













































import Vue from "vue";

export default Vue.extend({
  name: "LandingComponent",

  data: () => ({
    app_name: process.env.VUE_APP_TITLE,
  }),
});
